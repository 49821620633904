var template_path = $('html').data('path');
var child_path = template_path.replace('webwerk', 'webwerk-child');

/* additional js here */
$(document).ready(function() {
    var $myParagraph = $('p.passwd__error');
    var $myInput = $('input.passwd__input');
    
    // Detach the paragraph from its current location and append it after the input.
    $myParagraph.detach().insertAfter($myInput);
  });